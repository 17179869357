'use client';
import { loadConfig } from '@/services/config';
import { keycloakAuth } from '@/services/config/keycloak';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { KeycloakInstance } from 'keycloak-js';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import LayoutSkeleton from '../../LayoutSkeleton';
import { privateRoute } from '@/utils/security';

interface Props {
  children: JSX.Element;
  loading: JSX.Element;
}

function ConfigProvider({ children, loading }: Props) {
  const [loadingState, setLoadingState] = useState<boolean>(true);
  const [keycloakInstanceState, setKeycloakInstance] = useState<KeycloakInstance>();

  const basePath = '';
  useEffect(() => {
    fetch(`${basePath}/conf/config.json`)
      .then((response) => {
        return response.json();
      })
      .then((content: string) => {
        loadConfig(content);
        const result = keycloakAuth();
        setKeycloakInstance(result);
        setLoadingState(false);
      })
      .catch((error) => {
        console.log('erreur : ', error);
      });
  }, []);

  const keycloakInitOptions = { onLoad: 'login-required', checkLoginIframe: false };

  const pathname = usePathname();
  if (loadingState) {
    return loading;
  }
  if (keycloakInstanceState && privateRoute(pathname)) {
    return (
      <ReactKeycloakProvider
        authClient={keycloakInstanceState}
        initOptions={keycloakInitOptions}
        LoadingComponent={<LayoutSkeleton />}
      >
        {children}
      </ReactKeycloakProvider>
    );
  } else {
    return <>{children}</>;
  }
  // return <div>Keycloak error </div>;
}

export default ConfigProvider;
