'use client';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fr from './fr.json';

export const resources = {
  fr,
};

export const availableLanguages = Object.keys(resources);
export const defaultNS = 'common';

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  defaultNS,
  fallbackLng: 'fr',
  interpolation: {
    escapeValue: false,
  },
  supportedLngs: ['fr'],
  // debug: process.env.NODE_ENV === 'development',
});

export default i18n;
