import { Grid2, Skeleton } from '@mui/material';

const LayoutSkeleton: React.FC = () => {
  return (
    <>
      <Skeleton height={104} variant="rectangular" />
      <br />
      <Grid2 container flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
        <Skeleton height={100} width={600} variant="rectangular" />
        <br />
        <br />
        <Skeleton height={300} width={600} variant="rectangular" />
      </Grid2>
    </>
  );
};

export default LayoutSkeleton;
