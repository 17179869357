import { Box } from '@mui/material';
import Image from 'next/image';

const Logo = () => {
  const basePath = '';
  return (
    <Box>
      <Image src={`${basePath}/img/logo-immo-construction.jpg`} width={140} height={70} alt="Besse" />
    </Box>
  );
};
export default Logo;
