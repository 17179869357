import { SxProps, Theme } from '@mui/material';

const style: SxProps<Theme> = [
  {
    width: '100%',
    background: 'white',
    '& .content': {
      width: 'clamp(45ch, 90%, 1400px)',
      display: 'flex',
      alignItems: 'center',
      padding: '18px 0 12px 0',
      margin: 'auto',
      justifyContent: 'space-between',
    },

    '& .logo': {
      display: 'flex',
      gap: 2,
    },
  },
];

export default style;
