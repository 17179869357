import { Box } from '@mui/material';
import Link from '../Link';
import Logo from '../Logo';
import style from './styles';

const HomePageHeader = () => {
  return (
    <Box component="header" sx={style}>
      <div className="content">
        <div className="logo">
          <Link href={'/'}>
            <Logo />
          </Link>
        </div>
      </div>
    </Box>
  );
};

export default HomePageHeader;
