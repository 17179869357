import { SxProps, Theme } from '@mui/material';
import { greyBlue, theme } from '../providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    position: 'fixed',
    zIndex: 10,
    top: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: '18px 5% 12px 5%',
    margin: 'auto',
    justifyContent: 'space-between',
    background: greyBlue,

    '& .logo': {
      display: 'flex',
      gap: 2,
    },
    '& .info': {
      pr: 3,
      borderRight: '1px solid ' + theme.palette.grey[500],
    },
    '& .right-content': {
      display: 'flex',
      alignItems: 'center',
      gap: 2,
      '& .name': {},
    },
    '& .site': {
      display: 'flex',
      ml: '-2px',
      alignItems: 'center',
      fontWeight: '700',
      '& svg': {
        width: '0.8em',
      },
    },
  },
];

export default style;
