'use client';
import { Context } from '@/components/providers/ContextProvider';
import '@/i18n';
import { privateRoute } from '@/utils/security';
import { Box } from '@mui/material';
import { usePathname, useRouter } from 'next/navigation';
import { useContext, useEffect, useState, type JSX } from 'react';
import { isAuthenticated } from '../../services/config/keycloak';
import Footer from '../Footer';
import Header from '../Header';
import HomePageHeader from '../HomePageHeader';
import style from './styles';
import Script from 'next/script';
import { getIdAxeptio, getTagManager } from '@/services/config';

interface Props {
  children: JSX.Element;
}
function Layout({ children }: Props) {
  const { user } = useContext(Context);
  const { push } = useRouter();
  const pathname = usePathname();
  const authenticated = isAuthenticated();

  const [isAuthorized, setIsAuthorized] = useState<boolean>(false);

  useEffect(() => {
    if (!user?.roles?.find((role) => role === 'DETAIL_SINISTRE') && authenticated && privateRoute(pathname)) {
      setIsAuthorized(false);
      push('/unauthorized');
    } else {
      setIsAuthorized(true);
    }
  }, [user?.roles, authenticated, pathname]);
  const tagManager = getTagManager();
  const idAxeptio = getIdAxeptio();
  useEffect(() => {
    window.axeptioSettings = {
      clientId: idAxeptio,
      jsonCookieName: 'ImmoPlusCookies',
      userCookiesDuration: 180,
    };
    const element = document.querySelector('body');
    const script = document.createElement('script');
    script.src = '//static.axept.io/sdk-slim.js';
    script.async = true;
    element?.appendChild(script);
    return () => {
      element?.removeChild(script);
    };
  }, []);

  const [displayMatomo, setDisplayMatomo] = useState<boolean>(false);
  window._axcb = window._axcb || [];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  window._axcb.push((sdk: any) => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    sdk.on('cookies:complete', (choices: any) => {
      if (choices.Matomo && !displayMatomo) {
        setDisplayMatomo(true);
      }
    });
  });
  return (
    <>
      {' '}
      {tagManager && displayMatomo && (
        <Script
          id="matomo-tag-manager"
          dangerouslySetInnerHTML={{
            __html: `var _mtm = window._mtm = window._mtm || [];
      _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
      var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
      g.async=true; g.src='https://pbcanalytics.cabinet-besse.net/js/container_${tagManager}.js'; s.parentNode.insertBefore(g,s);`,
          }}
        ></Script>
      )}
      {authenticated && privateRoute(pathname) ? <Header /> : <HomePageHeader />}
      <Box component="section" sx={style}>
        {isAuthorized && children}
      </Box>
      {!privateRoute(pathname) && <Footer />}
    </>
  );
}

export default Layout;
