import { IconButton as MuiIconButton } from '@mui/material';
import React from 'react';
import style from './styles';

export interface Props {
  id: string | number;
  title: string;
  color?: 'primary' | 'secondary';
  icon: JSX.Element;
  onClick(id: string | number, e?: React.MouseEvent<HTMLElement>): void;
  infoTitle?: string;
}
function IconButton(props: Props) {
  const { title, color = 'primary', icon, onClick, id, infoTitle } = props;

  return (
    <MuiIconButton
      aria-label={title}
      color={color}
      sx={style}
      onClick={(e) => onClick(id, e)}
      title={infoTitle}
      disableRipple
    >
      {icon}
    </MuiIconButton>
  );
}

export default IconButton;
