import { getName, logout } from '@/services/config/keycloak';
import { LocationOn, PowerSettingsNew } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButton from '../IconButton';
import Link from '../Link';
import Logo from '../Logo';
import { Context } from '../providers/ContextProvider';
import style from './styles';

const Header = () => {
  const { t } = useTranslation('layout');
  const { site } = useContext(Context);
  const { keycloak } = useKeycloak();
  const [name, setName] = useState<string | undefined>();
  useEffect(() => {
    const name = getName();
    setName(name);
  }, [keycloak.tokenParsed]);

  return (
    <Box component="header" sx={style} position="fixed">
      <div className="logo">
        <Link href={'/'}>
          <Logo />
        </Link>
      </div>
      <nav className="right-content">
        <div className="info">
          <div className="name">Bonjour {name}</div>
          {site && (
            <div className="site">
              <LocationOn />
              {site.label}
            </div>
          )}
        </div>
        <IconButton
          id={'logout'}
          title={t('disconnect')}
          color="primary"
          icon={<PowerSettingsNew />}
          onClick={() => logout()}
        />
      </nav>
    </Box>
  );
};

export default Header;
