import { Box, Grid2 } from '@mui/material';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import styles from './styles';

const Footer = () => {
  const { t } = useTranslation('layout');
  return (
    <Box sx={styles}>
      {
        <Grid2 container alignItems="center">
          <div className={'menuFooter'}>
            <Link href={'/contact'}>{t('contact')}</Link>
            <Link href={'/mentions-legales'}>{t('legalNotices')}</Link>
            <Link href={'/donnees-personnelles'}>{t('dataProtection')}</Link>
          </div>
        </Grid2>
      }
    </Box>
  );
};

export default Footer;
