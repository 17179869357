import { SxProps, Theme } from '@mui/material';
import { theme } from '../providers/ThemeRegistry/theme';

const style: SxProps<Theme> = [
  {
    borderTop: '1px solid ' + theme.palette.grey[300],
    width: '100%',
    '& > div': {
      width: 'clamp(45ch, 90%, 1400px)',
      padding: '16px 0',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: 'auto',
      '& p': {
        fontSize: 12,
        color: theme.palette.grey[800],
      },
      flexWrap: 'wrap',
    },
    '& .menuFooter': {
      '& a': {
        color: theme.palette.grey[800],
        fontSize: 12,
        marginRight: 3,
        textDecoration: 'none',
        lineHeight: '24px',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    '@media all and (max-width: 580px)': {
      '& > div': {
        flexDirection: 'column',
      },
      '& .copyright': {
        margin: 'auto',
        marginTop: '20px',
        flexWrap: 'wrap',
        flexDirection: 'row',
      },
      '& .menuFooter': {
        '& a': {
          margin: '0 4px 0 4px',
          textAlign: 'center',
        },
      },
    },
  },
];

export default style;
